import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HomeComponent} from './home/home.component';
import {RouterModule, Routes} from '@angular/router';
import {AppComponent} from './app.component';
import {PromosComponent} from './promos/promos.component';
import {HvacComponent} from './hvac/hvac.component';
import {AboutusComponent} from './aboutus/aboutus.component';
import {ContactComponent} from './contact/contact.component';
import {FaqComponent} from './faq/faq.component';

const routes: Routes = [
  { path: 'home', component:  HomeComponent},
  { path: 'promos', component:  PromosComponent},
  { path: 'hvac', component:  HvacComponent},
  { path: 'about', component:  AboutusComponent},
  { path: 'contact', component:  ContactComponent},
  { path: 'faq', component:  FaqComponent}

];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes,{useHash: true}),
    CommonModule
  ],
  exports: [RouterModule]

})
export class AppRoutingModule { }
