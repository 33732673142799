import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceResponse} from '../../model/service-response';
import {ModelMarketingData} from '../../model/model-marketing-data';

@Injectable({
  providedIn: 'root'
})
export class ServicefaqcompService {

  constructor(private httpClient: HttpClient) { }

  getFaqs(){
    return this.httpClient.get<ServiceResponse>("assets/mockdata/faq/faqitems.json");
  }
}
