import { Component, OnInit } from '@angular/core';
import {ServicefaqcompService} from '../services/faq/servicefaqcomp.service';
import {ModelMarketingData} from '../model/model-marketing-data';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  model : Array<ModelMarketingData> = [];
  constructor(private servicefaqcompService:ServicefaqcompService) { }

  ngOnInit() {
    this.getAllFaqs();
  }

  getAllFaqs(){
    this.servicefaqcompService.getFaqs()
      .subscribe(responseData => {
      this.model = responseData.data;
      })
  }

}
