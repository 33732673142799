import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import * as $ from 'jquery';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { PromosComponent } from './promos/promos.component';
import { HvacComponent } from './hvac/hvac.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactComponent } from './contact/contact.component';
import { FaqComponent } from './faq/faq.component';
import {ServiceAppCompService} from './services/appComp/service-app-comp.service';
import {HttpClientModule} from '@angular/common/http';
import {ServiceaboutuscompService} from './services/aboutus/serviceaboutuscomp.service';
import {ServiceHomeCompService} from './services/homeComp/service-home-comp.service';
import {ServicehvaccompService} from './services/hvacComp/servicehvaccomp.service';
import {ServicePromosService} from './services/promos/service-promos.service';
import {ServicefaqcompService} from './services/faq/servicefaqcomp.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PromosComponent,
    HvacComponent,
    AboutusComponent,
    ContactComponent,
    FaqComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [ServiceAppCompService, ServiceaboutuscompService, ServiceHomeCompService, ServicehvaccompService, ServicePromosService,ServicefaqcompService],
  bootstrap: [AppComponent]
})
export class AppModule { }
