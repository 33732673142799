import { Component, OnInit } from '@angular/core';
import {ServiceaboutuscompService} from '../services/aboutus/serviceaboutuscomp.service';
import {ModelMarketingData} from '../model/model-marketing-data';
import {modelpageintroandcontent} from '../model/modelpageintroandcontent';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

   model: modelpageintroandcontent = new modelpageintroandcontent();
   modelphotogallery: Array<ModelMarketingData> = [];

  constructor(private serviceaboutuscompService: ServiceaboutuscompService) { }

  ngOnInit() {
    this.getPageIntroAndContent();
    this.getPhotoGallery();
  }

  getPageIntroAndContent(){
   this.serviceaboutuscompService.getAboutUsIntroAndContent()
     .subscribe(responseData => {
        this.model = responseData.data;
     })
  }

  getPhotoGallery(){
    this.serviceaboutuscompService.getAboutUsPhotoGallery()
      .subscribe(responseData => {
        this.modelphotogallery = responseData.data;
      })
  }

}
