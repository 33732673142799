import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceResponse} from '../../model/service-response';

@Injectable({
  providedIn: 'root'
})
export class ServicePromosService {

  constructor(private httpClient: HttpClient) { }

  getPromos() {
    return this.httpClient.get<ServiceResponse>("assets/mockdata/promos/promos.json");
  }


}
