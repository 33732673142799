import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceResponse} from '../../model/service-response';

@Injectable({
  providedIn: 'root'
})
export class ServiceAppCompService {

  constructor(private httpClient:HttpClient) { }

  getBannerData() {
    return this.httpClient.get<ServiceResponse>('assets/mockdata/bannerData.json');
  }

  getSideNameMarketingItems() {
    return this.httpClient.get<ServiceResponse>('assets/mockdata/sideNavMarketingData.json');
  }


}
