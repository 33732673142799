import { Component, OnInit } from '@angular/core';
import {ServiceHomeCompService} from '../services/homeComp/service-home-comp.service';
import {ModelMarketingData} from '../model/model-marketing-data';
import {ServiceResponse} from '../model/service-response';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

   modelData: Array<ModelMarketingData> = [];

  constructor(private serviceHomeComp:ServiceHomeCompService) {
  }

  ngOnInit() {
    this.getBulletPoints();
  }

  getBulletPoints(){
    this.serviceHomeComp.getHomeCompBulletPoints().subscribe((responseData: ServiceResponse) => {
      this.modelData = responseData.data;
    })
  }

}
