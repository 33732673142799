import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceResponse} from '../../model/service-response';

@Injectable({
  providedIn: 'root'
})
export class ServiceaboutuscompService {

  constructor(private httpClient: HttpClient) {
  }

  getAboutUsIntroAndContent() {
    return this.httpClient.get<ServiceResponse>("assets/mockdata/aboutus/aboutus.json");
  }


  getAboutUsPhotoGallery() {
    return this.httpClient.get<ServiceResponse>("assets/mockdata/aboutus/aboutusphotogallery.json");
  }

}
