import { Component, OnInit } from '@angular/core';
import {ServicehvaccompService} from '../services/hvacComp/servicehvaccomp.service';
import {ModelMarketingData} from '../model/model-marketing-data';
import {ServiceResponse} from '../model/service-response';

@Component({
  selector: 'app-hvac',
  templateUrl: './hvac.component.html',
  styleUrls: ['./hvac.component.css']
})
export class HvacComponent implements OnInit {
  model: Array<ModelMarketingData> = [];

  constructor(private servicehvaccompService:ServicehvaccompService) { }

  ngOnInit() {
    this.getAccordianData();
  }


  getAccordianData() {
    this.servicehvaccompService.getHvacCompAccordianData().subscribe((responseData: ServiceResponse) => {
      this.model = responseData.data;
    })
  }

}
