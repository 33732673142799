import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceResponse} from '../../model/service-response';

@Injectable({
  providedIn: 'root'
})
export class ServicehvaccompService {

  constructor(private httpclient: HttpClient) { }

  getHvacCompAccordianData(){
    return this.httpclient.get<ServiceResponse>("assets/mockdata/hvaccomp/hvacaccordiandata.json");
  }

}
