import { Component } from '@angular/core';
import {Router} from '@angular/router';
import * as $ from 'jquery';
import {ServiceAppCompService} from './services/appComp/service-app-comp.service';
import {ServiceResponse} from './model/service-response';
import {Bannerdata} from './model/bannerdata';
import {ModelMarketingData} from './model/model-marketing-data';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'allamerican';
  bannerModel: Bannerdata = new Bannerdata();
  sideNavMarketingModel: Array<ModelMarketingData> = [];

  constructor(private router: Router,private serviceComp: ServiceAppCompService) {
  }

  ngOnInit() {
    // On Page Refresh Nav to home page
    this.getBannerData();
    this.getSideNameMarketingData();
    this.router.navigate(['/home']);
  }

  getBannerData(){
    this.serviceComp.getBannerData().subscribe((responseData: ServiceResponse) => {
       this.bannerModel = responseData.data;
    });
  }

  getSideNameMarketingData(){
    this.serviceComp.getSideNameMarketingItems().subscribe((responseData: ServiceResponse) => {
      this.sideNavMarketingModel = responseData.data;
    });
  }

  navHome() {
   this.router.navigate(['/home']);
  }

  navPromos() {
    this.router.navigate(['/promos']);
  }

  navHvac() {
    this.router.navigate(['/hvac']);
  }

  navAboutUs() {
    this.router.navigate(['/about']);
  }

  navContactUs() {
    this.router.navigate(['/contact']);
  }

  navFaqs() {
    this.router.navigate(['/faq']);
  }


  collapseNavItems(){
    $(document).ready(function(){
      // $("#sidenavitems").children("button").click(function(){
      //   $("#togglebutton").attr("aria-expanded","false")
      //   $("#togglebutton").attr("class","navbar-toggler collapsed")
      //   $("#navbarTogglerDemo01").attr("class","collapse navbar-collapse")
      // })
      $("#togglebutton").attr("aria-expanded","false")
      $("#togglebutton").attr("class","navbar-toggler bg-dark w-100")
      $("#navbarTogglerDemo01").attr("class","collapse navbar-collapse")
    });
  }



}
