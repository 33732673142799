import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ModelMarketingData} from '../../model/model-marketing-data';
import {ServiceResponse} from '../../model/service-response';

@Injectable({
  providedIn: 'root'
})
export class ServiceHomeCompService {

  constructor(private httpClient : HttpClient) { }

  getHomeCompBulletPoints() {
    return this.httpClient.get<ServiceResponse>('assets/mockdata/homecomp/homecompbulletpoints.json');
  }
}
