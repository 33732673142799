import { Component, OnInit } from '@angular/core';
import {ServicePromosService} from '../services/promos/service-promos.service';
import {ModelMarketingData} from '../model/model-marketing-data';
import {modelpageintroandcontent} from '../model/modelpageintroandcontent';

@Component({
  selector: 'app-promos',
  templateUrl: './promos.component.html',
  styleUrls: ['./promos.component.css']
})
export class PromosComponent implements OnInit {
  model: modelpageintroandcontent = new modelpageintroandcontent();

  constructor(private servicePromosService: ServicePromosService) { }

  ngOnInit() {
    this.getAllPromo();
  }

  getAllPromo(){
    this.servicePromosService.getPromos()
      .subscribe(responseData =>{
        this.model = responseData.data;
      })
  }


}
